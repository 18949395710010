/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  HttpStatusCode,
  TalerError,
  assertUnreachable,
} from "@gnu-taler/taler-util";
import { Fragment, VNode, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { useInstanceBankAccounts } from "../../../../hooks/bank.js";
import { useInstanceTransfers } from "../../../../hooks/transfer.js";
import { LoginPage } from "../../../login/index.js";
import { ListPage } from "./ListPage.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";
import { useSessionContext } from "../../../../context/session.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { Notification } from "../../../../utils/types.js";
import { useTranslationContext } from "@gnu-taler/web-util/browser";

interface Props {
  onCreate: () => void;
}
interface Form {
  verified?: boolean;
  payto_uri?: string;
}

export default function ListTransfer({ onCreate }: Props): VNode {
  const setFilter = (s?: boolean) => setForm({ ...form, verified: s });
  const { i18n } = useTranslationContext();

  const { state, lib } = useSessionContext();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);

  const [position, setPosition] = useState<string | undefined>(undefined);

  const instance = useInstanceBankAccounts();
  const accounts =
    !instance || instance instanceof TalerError || instance.type === "fail"
      ? []
      : instance.body.accounts.map((a) => a.payto_uri);
  const [form, setForm] = useState<Form>({ payto_uri: "" });

  const shoulUseDefaultAccount = accounts.length === 1;
  useEffect(() => {
    if (shoulUseDefaultAccount) {
      setForm({ ...form, payto_uri: accounts[0] });
    }
  }, [shoulUseDefaultAccount]);

  const isVerifiedTransfers = form.verified === true;
  const isNonVerifiedTransfers = form.verified === false;
  const isAllTransfers = form.verified === undefined;

  const result = useInstanceTransfers(
    {
      position,
      payto_uri: form.payto_uri === "" ? undefined : form.payto_uri,
      verified: form.verified,
    },
    (id) => setPosition(id),
  );

  if (!result) return <Loading />;
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />;
  }
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />;
      }
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      default: {
        assertUnreachable(result);
      }
    }
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />

      <ListPage
        accounts={accounts}
        transfers={result.body}
        onLoadMoreBefore={result.isFirstPage ? undefined : result.loadFirst}
        onLoadMoreAfter={result.isLastPage ? undefined : result.loadNext}
        onCreate={onCreate}
        onDelete={async (transfer) => {
          try {
            const resp = await lib.instance.deleteWireTransfer(state.token, String(transfer.transfer_serial_id));
            if (resp.type === "ok") {
              setNotif({
                message: i18n.str`Wire transfer "${transfer.wtid.substring(0,16)}..." has been deleted`,
                type: "SUCCESS",
              });
            } else {
              setNotif({
                message: i18n.str`Failed to delete transfer`,
                type: "ERROR",
                description: resp.detail.hint,
              });
            }
          } catch (error) {
            setNotif({
              message: i18n.str`Failed to delete transfer`,
              type: "ERROR",
              description: error instanceof Error ? error.message : undefined,
            });
          }
        }}
        onShowAll={() => setFilter(undefined)}
        onShowUnverified={() => setFilter(false)}
        onShowVerified={() => setFilter(true)}
        isAllTransfers={isAllTransfers}
        isVerifiedTransfers={isVerifiedTransfers}
        isNonVerifiedTransfers={isNonVerifiedTransfers}
        payTo={form.payto_uri}
        onChangePayTo={(p) => setForm((v) => ({ ...v, payto_uri: p }))}
      />
    </Fragment>
  );
}
