import { TranslatedString } from "@gnu-taler/taler-util";
import { Fragment, VNode, h } from "preact";
import { UIFormProps } from "./FormProvider.js";
import { LabelWithTooltipMaybeRequired } from "./InputLine.js";
import { useField } from "./useField.js";
import { noHandlerPropsAndNoContextForField } from "./InputArray.js";

export interface ChoiceH<V> {
  label: TranslatedString;
  value: V;
}

export function InputChoiceHorizontal<T extends object, K extends keyof T>(
  props: {
    choices: ChoiceH<string>[];
  } & UIFormProps<T, K>,
): VNode {
  const { choices, label, tooltip, help, required, converter } = props;
  //FIXME: remove deprecated
  const fieldCtx = useField<T, K>(props.name);
  const { value, onChange, state } =
    props.handler ?? fieldCtx ?? noHandlerPropsAndNoContextForField(props.name);
  if (state.hidden) {
    return <Fragment />;
  }

  return (
    <div class="sm:col-span-6">
      <LabelWithTooltipMaybeRequired
        label={label}
        required={required}
        tooltip={tooltip}
      />
      <fieldset class="mt-2">
        <div class="isolate inline-flex rounded-md shadow-sm">
          {choices.map((choice, idx) => {
            const convertedValue = converter?.fromStringUI(choice.value as any)
            const isFirst = idx === 0;
            const isLast = idx === choices.length - 1;
            let clazz =
              "relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-10";
            if (convertedValue !== undefined && convertedValue === value) {
              clazz +=
                " text-white bg-indigo-600 hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500";
            } else {
              clazz += " hover:bg-gray-100 border-gray-300";
            }
            if (isFirst) {
              clazz += " rounded-l-md";
            } else {
              clazz += " -ml-px";
            }
            if (isLast) {
              clazz += " rounded-r-md";
            }
            return (
              <button
                type="button"
                key={idx}
                disabled={state.disabled}
                label={choice.label}
                class={clazz}
                onClick={(e) => {
                  onChange(
                    (value === choice.value ? undefined : convertedValue) as any,
                  );
                }}
              >
                {choice.label}
              </button>
            );
          })}
        </div>
      </fieldset>
      {help && (
        <p class="mt-2 text-sm text-gray-500" id="email-description">
          {help}
        </p>
      )}
    </div>
  );
}
