import { AmountJson, Amounts, TranslatedString } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import { UIFormProps } from "./FormProvider.js";
import { InputLine } from "./InputLine.js";
import { useField } from "./useField.js";
import { noHandlerPropsAndNoContextForField } from "./InputArray.js";

export function InputAmount<T extends object, K extends keyof T>(
  props: { currency?: string } & UIFormProps<T, K>,
): VNode {
  //FIXME: remove deprecated
  const fieldCtx = useField<T, K>(props.name);
  const { value } =
    props.handler ?? fieldCtx ?? noHandlerPropsAndNoContextForField(props.name);
  const currency =
    !value || !(value as any).currency
      ? props.currency
      : (value as any).currency;
  return (
    <InputLine<T, K>
      {...props}
      type="text"
      before={{
        type: "text",
        text: currency as TranslatedString,
      }}
      //@ts-ignore
      converter={
        props.converter ?? {
          fromStringUI: (v): AmountJson => {
            return (
              Amounts.parse(`${currency}:${v}`) ??
              Amounts.zeroOfCurrency(currency)
            );
          },
          toStringUI: (v: AmountJson) => {
            return v === undefined ? "" : Amounts.stringifyValue(v);
          },
        }
      }
    />
  );
}
