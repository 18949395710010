/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  randomRfc3548Base32Key,
  TalerMerchantApi,
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import { FormProvider } from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputSelector } from "../../../../components/form/InputSelector.js";
import { InputWithAddon } from "../../../../components/form/InputWithAddon.js";
import { WithId } from "../../../../declaration.js";

type Entity = TalerMerchantApi.OtpDevicePatchDetails & WithId;

interface Props {
  onUpdate: (d: Entity) => Promise<void>;
  onBack?: () => void;
  device: Entity;
}
const algorithms = [0, 1, 2];
const algorithmsNames = ["off", "30s 8d TOTP-SHA1", "30s 8d eTOTP-SHA1"];
export function UpdatePage({ device, onUpdate, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();

  const [state, setState] = useState<Partial<Entity>>(device);
  const [showKey, setShowKey] = useState(false);

  const submitForm = () => {
    return onUpdate(state as Entity);
  };

  return (
    <div>
      <section class="section">
        <section class="hero is-hero-bar">
          <div class="hero-body">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span class="is-size-4">
                    <i18n.Translate>Device:</i18n.Translate>
                    <b>{device.id}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />

        <section class="section is-main-section">
          <div class="columns">
            <div class="column is-four-fifths">
              <FormProvider object={state} valueHandler={setState}>
                <Input<Entity>
                  name="otp_device_description"
                  label={i18n.str`Description`}
                  tooltip={i18n.str`Useful to identify the device physically`}
                />
                <InputSelector<Entity>
                  name="otp_algorithm"
                  label={i18n.str`Verification algorithm`}
                  tooltip={i18n.str`Algorithm to use to verify transaction in offline mode`}
                  values={algorithms}
                  toStr={(v) => algorithmsNames[v]}
                  fromStr={(v) => Number(v)}
                />
                {state.otp_algorithm && state.otp_algorithm > 0 ? (
                  <Fragment>
                    <InputWithAddon<Entity>
                      name="otp_key"
                      label={i18n.str`Device key`}
                      readonly={state.otp_key === undefined}
                      inputType={showKey ? "text" : "password"}
                      help={
                        state.otp_key === undefined
                          ? i18n.str`Not modified`
                          : i18n.str`Be sure to be very hard to guess or use the random generator`
                      }
                      tooltip={i18n.str`Your device need to have exactly the same value`}
                      fromStr={(v) => v.toUpperCase()}
                      addonAfterAction={() => {
                        setShowKey(!showKey);
                      }}
                      addonAfter={
                        <span
                          class="icon"
                          onClick={() => {
                            setShowKey(!showKey);
                          }}
                        >
                          {showKey ? (
                            <i class="mdi mdi-eye" />
                          ) : (
                            <i class="mdi mdi-eye-off" />
                          )}
                        </span>
                      }
                      side={
                        state.otp_key === undefined ? (
                          <button
                            onClick={() => {
                              setState((s) => ({ ...s, otp_key: "" }));
                            }}
                            class="button"
                          >
                            <i18n.Translate>Change key</i18n.Translate>
                          </button>
                        ) : (
                          <button
                            data-tooltip={i18n.str`Generate random secret key`}
                            class="button is-info mr-3"
                            onClick={() => {
                              setState((s) => ({
                                ...s,
                                otp_key: randomRfc3548Base32Key(),
                              }));
                            }}
                          >
                            <i18n.Translate>Random</i18n.Translate>
                          </button>
                        )
                      }
                    />
                  </Fragment>
                ) : undefined}{" "}
              </FormProvider>

              <div class="buttons is-right mt-5">
                {onBack && (
                  <button class="button" onClick={onBack}>
                    <i18n.Translate>Cancel</i18n.Translate>
                  </button>
                )}
                <AsyncButton
                  disabled={false}
                  data-tooltip={i18n.str`Confirm operation`}
                  onClick={submitForm}
                >
                  <i18n.Translate>Confirm</i18n.Translate>
                </AsyncButton>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
