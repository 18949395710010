/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { TalerError, TalerErrorCode } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { NotificationCard } from "./menu/index.js";

/**
 * equivalent to ErrorLoading for merchant-backoffice which uses notification-card
 * @param param0
 * @returns
 */
export function ErrorLoadingMerchant({
  error,
}: {
  error: TalerError;
  showDetail?: boolean;
}): VNode {
  const { i18n } = useTranslationContext();
  switch (error.errorDetail.code) {
    //////////////////
    // Every error that can be produce in a Http Request
    //////////////////
    case TalerErrorCode.GENERIC_TIMEOUT: {
      if (error.hasErrorCode(TalerErrorCode.GENERIC_TIMEOUT)) {
        const { requestMethod, requestUrl, timeoutMs } = error.errorDetail;
        return (
          <NotificationCard
            notification={{
              type: "ERROR",
              message: i18n.str`The request reached a timeout, check your connection.`,
              description: error.message,
              details: JSON.stringify(
                { requestMethod, requestUrl, timeoutMs },
                undefined,
                2,
              ),
            }}
          />
        );
      }
      break;
    }
    case TalerErrorCode.GENERIC_CLIENT_INTERNAL_ERROR: {
      if (error.hasErrorCode(TalerErrorCode.GENERIC_CLIENT_INTERNAL_ERROR)) {
        const { requestMethod, requestUrl, timeoutMs } = error.errorDetail;
        return (
          <NotificationCard
            notification={{
              type: "ERROR",
              message: i18n.str`The request was cancelled.`,
              description: error.message,
              details: JSON.stringify(
                { requestMethod, requestUrl, timeoutMs },
                undefined,
                2,
              ),
            }}
          />
        );
      }
      break;
    }
    case TalerErrorCode.WALLET_HTTP_REQUEST_GENERIC_TIMEOUT: {
      if (
        error.hasErrorCode(TalerErrorCode.WALLET_HTTP_REQUEST_GENERIC_TIMEOUT)
      ) {
        const { requestMethod, requestUrl, timeoutMs } = error.errorDetail;
        return (
          <NotificationCard
            notification={{
              type: "ERROR",
              message: i18n.str`The request reached a timeout, check your connection.`,
              description: error.message,
              details: JSON.stringify(
                { requestMethod, requestUrl, timeoutMs },
                undefined,
                2,
              ),
            }}
          />
        );
      }
      break;
    }
    case TalerErrorCode.WALLET_HTTP_REQUEST_THROTTLED: {
      if (error.hasErrorCode(TalerErrorCode.WALLET_HTTP_REQUEST_THROTTLED)) {
        const { requestMethod, requestUrl, throttleStats } = error.errorDetail;
        return (
          <NotificationCard
            notification={{
              type: "ERROR",
              message: i18n.str`A lot of request were made to the same server and this action was throttled.`,
              description: error.message,
              details: JSON.stringify(
                { requestMethod, requestUrl, throttleStats },
                undefined,
                2,
              ),
            }}
          />
        );
      }
      break;
    }
    case TalerErrorCode.WALLET_RECEIVED_MALFORMED_RESPONSE: {
      if (
        error.hasErrorCode(TalerErrorCode.WALLET_RECEIVED_MALFORMED_RESPONSE)
      ) {
        const { requestMethod, requestUrl, httpStatusCode, validationError } =
          error.errorDetail;
        return (
          <NotificationCard
            notification={{
              type: "ERROR",
              message: i18n.str`The response of the request is malformed.`,
              description: error.message,
              details: JSON.stringify(
                { requestMethod, requestUrl, httpStatusCode, validationError },
                undefined,
                2,
              ),
            }}
          />
        );
      }
      break;
    }
    case TalerErrorCode.WALLET_NETWORK_ERROR: {
      if (error.hasErrorCode(TalerErrorCode.WALLET_NETWORK_ERROR)) {
        const { requestMethod, requestUrl } = error.errorDetail;
        return (
          <NotificationCard
            notification={{
              type: "ERROR",
              message: i18n.str`Could not complete the request due to a network problem.`,
              description: error.message,
              details: JSON.stringify(
                { requestMethod, requestUrl },
                undefined,
                2,
              ),
            }}
          />
        );
      }
      break;
    }
    case TalerErrorCode.WALLET_UNEXPECTED_REQUEST_ERROR: {
      if (error.hasErrorCode(TalerErrorCode.WALLET_UNEXPECTED_REQUEST_ERROR)) {
        const { requestMethod, requestUrl, httpStatusCode, errorResponse } =
          error.errorDetail;
        return (
          <NotificationCard
            notification={{
              type: "ERROR",
              message: i18n.str`Unexpected request error.`,
              description: error.message,
              details: JSON.stringify(
                { requestMethod, requestUrl, httpStatusCode, errorResponse },
                undefined,
                2,
              ),
            }}
          />
        );
      }
      break;
    }
    //////////////////
    // Every other error
    //////////////////
    // case TalerErrorCode.WALLET_UNEXPECTED_REQUEST_ERROR: {
    //   return <Attention type="danger" title={i18n.str``}>
    //   </Attention>
    // }
    //////////////////
    // Default message for unhandled case
    //////////////////
    default: {
      return (
        <NotificationCard
          notification={{
            type: "ERROR",
            message: i18n.str`Unexpected error.`,
            description: error.message,
            details: JSON.stringify(error.errorDetail, undefined, 2),
          }}
        />
      );
    }
  }
  /**
   * This should not happen
   *
   * The only reason why this is possible is because the case statement
   * follows and if `if (error.hasErrorCode` which returned false.
   *
   * TODO: add a better check
   */
  return (
    <NotificationCard
      notification={{
        type: "ERROR",
        message: i18n.str`Unexpected error.`,
        description: error.message,
        details: JSON.stringify(error.errorDetail, undefined, 2),
      }}
    />
  );
}
