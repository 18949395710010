/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { Link, route } from "preact-router";
import { NotificationCard } from "../../components/menu/index.js";
import {
  cleanAllCache,
  DEFAULT_ADMIN_USERNAME,
  useSessionContext,
} from "../../context/session.js";
import InstanceCreatePage from "../../paths/admin/create/index.js";
import { InstancePaths } from "../../Routing.js";

function NotFoundPage(): VNode {
  return (
    <div>
      <p>That page doesn&apos;t exist.</p>
      <Link href="/">
        <h4>Back to Home</h4>
      </Link>
    </div>
  );
}

export function NotFoundPageOrAdminCreate(): VNode {
  const { state } = useSessionContext();
  const { i18n } = useTranslationContext();
  if (state.isAdmin && state.instance === DEFAULT_ADMIN_USERNAME) {
    return (
      <Fragment>
        <NotificationCard
          notification={{
            message: i18n.str`No 'default' instance configured yet.`,
            description: i18n.str`Create a 'default' instance to begin using the merchant backoffice.`,
            type: "INFO",
          }}
        />
        <InstanceCreatePage
          forceId={DEFAULT_ADMIN_USERNAME}
          onConfirm={() => {
            // we need to clear everything since we take some
            // 404 as "default instance don't exist"
            cleanAllCache()
            route(InstancePaths.bank_list);
          }}
        />
      </Fragment>
    );
  }

  return <NotFoundPage />
}
