/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import {
  amountFractionalBase,
  AmountJson,
  Amounts,
  TalerMerchantApi,
} from "@gnu-taler/taler-util";

/**
 * merge refund with the same description and a difference less than one minute
 * @param prev list of refunds that will hold the merged refunds
 * @param cur new refund to add to the list
 * @returns list with the new refund, may be merged with the last
 */
export function mergeRefunds(
  prev: TalerMerchantApi.RefundDetails[],
  cur: TalerMerchantApi.RefundDetails,
): TalerMerchantApi.RefundDetails[] {
  let tail;

  if (
    prev.length === 0 || //empty list
    cur.timestamp.t_s === "never" || //current does not have timestamp
    (tail = prev[prev.length - 1]).timestamp.t_s === "never" || // last does not have timestamp
    cur.reason !== tail.reason || //different reason
    cur.pending !== tail.pending || //different pending state
    Math.abs(cur.timestamp.t_s - tail.timestamp.t_s) > 1000 * 60
  ) {
    //more than 1 minute difference

    //can't merge refunds, they are different or to distant in time
    prev.push(cur);
    return prev;
  }

  const a = Amounts.parseOrThrow(tail.amount);
  const b = Amounts.parseOrThrow(cur.amount);
  const r = Amounts.add(a, b).amount;

  prev[prev.length - 1] = {
    ...tail,
    amount: Amounts.stringify(r),
  };

  return prev;
}

export function rate(a: AmountJson, b: AmountJson): number {
  const af = toFloat(a);
  const bf = toFloat(b);
  if (bf === 0) return 0;
  return af / bf;
}

function toFloat(amount: AmountJson): number {
  return amount.value + amount.fraction / amountFractionalBase;
}
