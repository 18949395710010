/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { useSessionContext } from "../context/session.js";

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import { AccessToken, TalerHttpError, TalerMerchantApi, TalerMerchantManagementResultByMethod } from "@gnu-taler/taler-util";
import _useSWR, { SWRHook, mutate } from "swr";
const useSWR = _useSWR as unknown as SWRHook;

export function revalidateTokenFamilies() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "listTokenFamilies",
    undefined,
    { revalidate: true },
  );
}
export function useInstanceTokenFamilies() {
  const { state, lib } = useSessionContext();

  // const [offset, setOffset] = useState<number | undefined>();

  async function fetcher([token, _bid]: [AccessToken, number]) {
    return await lib.instance.listTokenFamilies(token, {
      // limit: PAGINATED_LIST_REQUEST,
      // offset: bid === undefined ? undefined: String(bid),
      // order: "dec",
    });
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"listTokenFamilies">,
    TalerHttpError
  >([state.token, "offset", "listTokenFamilies"], fetcher);

  if (error) return error;
  if (data === undefined) return undefined;
  if (data.type !== "ok") return data;

  return data;
}

export function revalidateTokenFamilyDetails() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "getTokenFamilyDetails",
    undefined,
    { revalidate: true },
  );
}
export function useTokenFamilyDetails(tokenFamilySlug: string) {
  const { state, lib } = useSessionContext();

  async function fetcher([slug, token]: [string, AccessToken]) {
    return await lib.instance.getTokenFamilyDetails(token, slug);
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"getTokenFamilyDetails">,
    TalerHttpError
  >([tokenFamilySlug, state.token, "getTokenFamilyDetails"], fetcher);

  if (error) return error;
  if (data === undefined) return undefined;
  if (data.type !== "ok") return data;

  return data;
}

export interface TokenFamilyAPI {
  createTokenFamily: (
    data: TalerMerchantApi.TokenFamilyCreateRequest,
  ) => Promise<void>;
  updateTokenFamily: (
    slug: string,
    data: TalerMerchantApi.TokenFamilyUpdateRequest,
  ) => Promise<void>;
  deleteTokenFamily: (slug: string) => Promise<void>;
}
