/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { TranslatedString } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { useState } from "preact/hooks";

export function JumpToElementById({
  testIfExist,
  onSelect,
  placeholder,
  description,
}: {
  placeholder: TranslatedString;
  description: TranslatedString;
  testIfExist: (id: string) => Promise<boolean>;
  onSelect: (id: string) => void;
}): VNode {
  const { i18n } = useTranslationContext();

  const [error, setError] = useState<string | undefined>(undefined);

  const [id, setId] = useState<string>();
  async function check(currentId: string | undefined): Promise<void> {
    if (!currentId) {
      setError(i18n.str`Missing id`);
      return;
    }
    try {
      const exi = await testIfExist(currentId);
      if (exi) {
        onSelect(currentId);
        setError(undefined);
      } else {
        setError(i18n.str`Not found`);
      }
    } catch {
      setError(i18n.str`Not found`);
    }
  }

  return (
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <div class="field has-addons">
            <div class="control">
              <input
                class={error ? "input is-danger" : "input"}
                type="text"
                value={id ?? ""}
                onChange={(e) => setId(e.currentTarget.value)}
                placeholder={placeholder}
              />
              {error && <p class="help is-danger">{error}</p>}
            </div>
            <span class="has-tooltip-bottom" data-tooltip={description}>
              <button class="button" onClick={() => check(id)}>
                <span class="icon">
                  <i class="mdi mdi-arrow-right" />
                </span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
