/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { TalerMerchantApi } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { QR } from "../../../../components/exception/QR.js";
import { CreatedSuccessfully as Template } from "../../../../components/notifications/CreatedSuccessfully.js";
import { useSessionContext } from "../../../../context/session.js";

type Entity = TalerMerchantApi.OtpDeviceAddDetails;

interface Props {
  entity: Entity;
  onConfirm: () => void;
}

export function CreatedSuccessfully({
  entity,
  onConfirm,
}: Props): VNode {
  const { i18n } = useTranslationContext();
  const { state } = useSessionContext();
  const issuer = state.backendUrl.href;
  const qrText = `otpauth://totp/${state.instance}/${entity.otp_device_id}?issuer=${issuer}&algorithm=SHA1&digits=8&period=30&secret=${entity.otp_key}`;
  const qrTextSafe = `otpauth://totp/${state.instance}/${entity.otp_device_id}?issuer=${issuer}&algorithm=SHA1&digits=8&period=30&secret=${entity.otp_key.substring(0, 6)}...`;

  return (
    <Template onConfirm={onConfirm} >
      <p class="is-size-5">
        <i18n.Translate>
          You can scan the next QR code with your device or save the key before continuing.
        </i18n.Translate>
      </p>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">ID</label>
        </div>
        <div class="field-body is-flex-grow-3">
          <div class="field">
            <p class="control">
              <input
                readonly
                class="input"
                value={entity.otp_device_id}
              />
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label"><i18n.Translate>Description</i18n.Translate></label>
        </div>
        <div class="field-body is-flex-grow-3">
          <div class="field">
            <p class="control">
              <input
                class="input"
                readonly
                value={entity.otp_device_description}
              />
            </p>
          </div>
        </div>
      </div>
      <QR
        text={qrText}
      />
      <div
        style={{
          color: "grey",
          fontSize: "small",
          width: 200,
          textAlign: "center",
          margin: "auto",
          wordBreak: "break-all",
        }}
      >
        {qrTextSafe}
      </div>
    </Template>
  );
}

