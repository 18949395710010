import {
  HttpRequestLibrary,
  readSuccessResponseJsonOrThrow,
  readTalerErrorResponse,
} from "../http-common.js";
import { HttpStatusCode } from "../http-status-codes.js";
import { createPlatformHttpLib } from "../http.js";
import { LibtoolVersion } from "../libtool-version.js";
import { hash } from "../nacl-fast.js";
import {
  FailCasesByMethod,
  OperationFail,
  OperationOk,
  ResultByMethod,
  opEmptySuccess,
  opFixedSuccess,
  opKnownAlternativeFailure,
  opKnownHttpFailure,
  opSuccessFromHttp,
  opUnknownFailure
} from "../operation.js";
import {
  TalerSignaturePurpose,
  buildSigPS,
  decodeCrock,
  eddsaSign,
  encodeCrock,
  stringToBytes,
  timestampRoundedToBuffer
} from "../taler-crypto.js";
import {
  AccessToken,
  AmountString,
  OfficerAccount,
  PaginationParams,
  ReserveAccount,
  SigningKey,
  codecForTalerCommonConfigResponse
} from "../types-taler-common.js";
import {
  AmlDecisionRequest,
  ExchangeVersionResponse,
  KycRequirementInformationId,
  WalletKycRequest,
  codecForAccountKycStatus,
  codecForAmlDecisionsResponse,
  codecForAmlKycAttributes,
  codecForAmlWalletKycCheckResponse,
  codecForAvailableMeasureSummary,
  codecForEventCounter,
  codecForExchangeConfig,
  codecForExchangeKeys,
  codecForKycProcessClientInformation,
  codecForKycProcessStartInformation,
  codecForLegitimizationNeededResponse
} from "../types-taler-exchange.js";
import { CacheEvictor, addMerchantPaginationParams, nullEvictor } from "./utils.js";

import { TalerError } from "../errors.js";
import { TalerErrorCode } from "../taler-error-codes.js";
import { codecForEmptyObject } from "../types-taler-wallet.js";

export type TalerExchangeResultByMethod<
  prop extends keyof TalerExchangeHttpClient,
> = ResultByMethod<TalerExchangeHttpClient, prop>;
export type TalerExchangeErrorsByMethod<
  prop extends keyof TalerExchangeHttpClient,
> = FailCasesByMethod<TalerExchangeHttpClient, prop>;

export enum TalerExchangeCacheEviction {
  CREATE_DESCISION,
}

/**
 */
export class TalerExchangeHttpClient {
  httpLib: HttpRequestLibrary;
  public readonly PROTOCOL_VERSION = "20:0:0";
  cacheEvictor: CacheEvictor<TalerExchangeCacheEviction>;

  constructor(
    readonly baseUrl: string,
    httpClient?: HttpRequestLibrary,
    cacheEvictor?: CacheEvictor<TalerExchangeCacheEviction>,
  ) {
    this.httpLib = httpClient ?? createPlatformHttpLib();
    this.cacheEvictor = cacheEvictor ?? nullEvictor;
  }

  isCompatible(version: string): boolean {
    const compare = LibtoolVersion.compare(this.PROTOCOL_VERSION, version);
    return compare?.compatible ?? false;
  }
  /**
   * https://docs.taler.net/core/api-exchange.html#get--seed
   *
   */
  async getSeed() {
    const url = new URL(`seed`, this.baseUrl);
    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
    });
    switch (resp.status) {
      case HttpStatusCode.Ok:
        const buffer = await resp.bytes();
        const uintar = new Uint8Array(buffer);

        return opFixedSuccess(uintar);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }
  /**
   * https://docs.taler.net/core/api-exchange.html#get--config
   *
   */
  async getConfig(): Promise<
    | OperationFail<HttpStatusCode.NotFound>
    | OperationOk<ExchangeVersionResponse>
  > {
    const url = new URL(`config`, this.baseUrl);
    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
    });
    switch (resp.status) {
      case HttpStatusCode.Ok: {
        const minBody = await readSuccessResponseJsonOrThrow(
          resp,
          codecForTalerCommonConfigResponse(),
        );
        const expectedName = "taler-exchange";
        if (minBody.name !== expectedName) {
          throw TalerError.fromUncheckedDetail({
            code: TalerErrorCode.GENERIC_UNEXPECTED_REQUEST_ERROR,
            requestUrl: resp.requestUrl,
            httpStatusCode: resp.status,
            detail: `Unexpected server component name (got ${minBody.name}, expected ${expectedName}})`,
          });
        }
        if (!this.isCompatible(minBody.version)) {
          throw TalerError.fromUncheckedDetail({
            code: TalerErrorCode.GENERIC_CLIENT_UNSUPPORTED_PROTOCOL_VERSION,
            requestUrl: resp.requestUrl,
            httpStatusCode: resp.status,
            detail: `Unsupported protocol version, client supports ${this.PROTOCOL_VERSION}, server supports ${minBody.version}`,
          });
        }
        // Now that we've checked the basic body, re-parse the full response.
        const body = await readSuccessResponseJsonOrThrow(
          resp,
          codecForExchangeConfig(),
        );
        return {
          type: "ok",
          body,
        };
      }
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }
  /**
   * https://docs.taler.net/core/api-merchant.html#get--config
   *
   * PARTIALLY IMPLEMENTED!!
   */
  async getKeys() {
    const url = new URL(`keys`, this.baseUrl);
    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
    });
    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForExchangeKeys());
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  // TERMS

  //
  // KYC operations
  //

  /**
   * https://docs.taler.net/core/api-exchange.html#post--kyc-wallet
   *
   */
  async notifyKycBalanceLimit(account: ReserveAccount, balance: AmountString) {
    const url = new URL(`kyc-wallet`, this.baseUrl);

    const body: WalletKycRequest = {
      balance,
      reserve_pub: account.id,
      reserve_sig: encodeCrock(account.signingKey),
    }

    const resp = await this.httpLib.fetch(url.href, {
      method: "POST",
      body,
    });

    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForAmlWalletKycCheckResponse());
      case HttpStatusCode.NoContent:
        return opEmptySuccess(resp);
      case HttpStatusCode.Forbidden:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.UnavailableForLegalReasons:
        return opKnownAlternativeFailure(resp, resp.status, codecForLegitimizationNeededResponse());
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  /**
   * https://docs.taler.net/core/api-exchange.html#post--kyc-wallet
   *
   */
  async checkKycStatus(account: ReserveAccount, requirementId: number, params: {
    timeout?: number,
  } = {}) {
    const url = new URL(`kyc-check/${String(requirementId)}`, this.baseUrl);

    if (params.timeout !== undefined) {
      url.searchParams.set("timeout_ms", String(params.timeout));
    }

    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
      headers: {
        "Account-Owner-Signature": buildKYCQuerySignature(account.signingKey),
      },
    });

    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForAccountKycStatus());
      case HttpStatusCode.Accepted:
        return opKnownAlternativeFailure(resp, resp.status, codecForAccountKycStatus());
      case HttpStatusCode.NoContent:
        return opEmptySuccess(resp);
      case HttpStatusCode.Forbidden:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.Conflict:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  /**
     * https://docs.taler.net/core/api-exchange.html#get--kyc-info-$ACCESS_TOKEN
     *
     */
  async checkKycInfo(token: AccessToken, known: KycRequirementInformationId[], params: {
    timeout?: number,
  } = {}) {
    const url = new URL(`kyc-info/${token}`, this.baseUrl);

    if (params.timeout !== undefined) {
      url.searchParams.set("timeout_ms", String(params.timeout));
    }

    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
      headers: {
        "If-None-Match": known.length ? known.join(",") : undefined
      }
    });

    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForKycProcessClientInformation());
      case HttpStatusCode.NoContent:
        return opKnownAlternativeFailure(resp, HttpStatusCode.NoContent, codecForEmptyObject());
      case HttpStatusCode.NotModified:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }


  /**
   * https://docs.taler.net/core/api-exchange.html#post--kyc-upload-$ID
   *
   */
  async uploadKycForm(requirement: KycRequirementInformationId, body: object) {
    const url = new URL(`kyc-upload/${requirement}`, this.baseUrl);

    const resp = await this.httpLib.fetch(url.href, {
      method: "POST",
      body,
    });

    switch (resp.status) {
      case HttpStatusCode.NoContent:
        return opEmptySuccess(resp);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.Conflict:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.PayloadTooLarge:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  /**
   * https://docs.taler.net/core/api-exchange.html#post--kyc-start-$ID
   *
   */
  async startKycProcess(requirement: KycRequirementInformationId, body: object = {}) {
    const url = new URL(`kyc-start/${requirement}`, this.baseUrl);


    const resp = await this.httpLib.fetch(url.href, {
      method: "POST",
      body
    });

    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForKycProcessStartInformation());
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.Conflict:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.PayloadTooLarge:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  //
  // AML operations
  //

  /**
   * https://docs.taler.net/core/api-exchange.html#get--aml-$OFFICER_PUB-decisions-$STATE
   *
   */
  // async getDecisionsByState(
  //   auth: OfficerAccount,
  //   state: TalerExchangeApi.AmlState,
  //   pagination?: PaginationParams,
  // ) {
  //   const url = new URL(
  //     `aml/${auth.id}/decisions/${TalerExchangeApi.AmlState[state]}`,
  //     this.baseUrl,
  //   );
  //   addPaginationParams(url, pagination);

  //   const resp = await this.httpLib.fetch(url.href, {
  //     method: "GET",
  //     headers: {
  //       "Taler-AML-Officer-Signature": buildQuerySignature(auth.signingKey),
  //     },
  //   });

  //   switch (resp.status) {
  //     case HttpStatusCode.Ok:
  //       return opSuccessFromHttp(resp, codecForAmlRecords());
  //     case HttpStatusCode.NoContent:
  //       return opFixedSuccess({ records: [] });
  //     //this should be unauthorized
  //     case HttpStatusCode.Forbidden:
  //       return opKnownHttpFailure(resp.status, resp);
  //     case HttpStatusCode.Unauthorized:
  //       return opKnownHttpFailure(resp.status, resp);
  //     case HttpStatusCode.NotFound:
  //       return opKnownHttpFailure(resp.status, resp);
  //     case HttpStatusCode.Conflict:
  //       return opKnownHttpFailure(resp.status, resp);
  //     default:
  //       return opUnknownFailure(resp, await readTalerErrorResponse(resp));
  //   }
  // }

  // /**
  //  * https://docs.taler.net/core/api-exchange.html#get--aml-$OFFICER_PUB-decision-$H_PAYTO
  //  *
  //  */
  // async getDecisionDetails(auth: OfficerAccount, account: string) {
  //   const url = new URL(`aml/${auth.id}/decision/${account}`, this.baseUrl);

  //   const resp = await this.httpLib.fetch(url.href, {
  //     method: "GET",
  //     headers: {
  //       "Taler-AML-Officer-Signature": buildQuerySignature(auth.signingKey),
  //     },
  //   });

  //   switch (resp.status) {
  //     case HttpStatusCode.Ok:
  //       return opSuccessFromHttp(resp, codecForAmlDecisionDetails());
  //     case HttpStatusCode.NoContent:
  //       return opFixedSuccess({ aml_history: [], kyc_attributes: [] });
  //     //this should be unauthorized
  //     case HttpStatusCode.Forbidden:
  //       return opKnownHttpFailure(resp.status, resp);
  //     case HttpStatusCode.Unauthorized:
  //       return opKnownHttpFailure(resp.status, resp);
  //     case HttpStatusCode.NotFound:
  //       return opKnownHttpFailure(resp.status, resp);
  //     case HttpStatusCode.Conflict:
  //       return opKnownHttpFailure(resp.status, resp);
  //     default:
  //       return opUnknownFailure(resp, await readTalerErrorResponse(resp));
  //   }
  // }

  // /**
  //  * https://docs.taler.net/core/api-exchange.html#post--aml-$OFFICER_PUB-decision
  //  *
  //  */
  // async addDecisionDetails(
  //   auth: OfficerAccount,
  //   decision: Omit<TalerExchangeApi.AmlDecision, "officer_sig">,
  // ) {
  //   const url = new URL(`aml/${auth.id}/decision`, this.baseUrl);

  //   const body = buildDecisionSignature(auth.signingKey, decision);
  //   const resp = await this.httpLib.fetch(url.href, {
  //     method: "POST",
  //     body,
  //   });

  //   switch (resp.status) {
  //     case HttpStatusCode.NoContent:
  //       return opEmptySuccess(resp);
  //     //FIXME: this should be unauthorized
  //     case HttpStatusCode.Forbidden:
  //       return opKnownHttpFailure(resp.status, resp);
  //     case HttpStatusCode.Unauthorized:
  //       return opKnownHttpFailure(resp.status, resp);
  //     //FIXME: this two need to be split by error code
  //     case HttpStatusCode.NotFound:
  //       return opKnownHttpFailure(resp.status, resp);
  //     case HttpStatusCode.Conflict:
  //       return opKnownHttpFailure(resp.status, resp);
  //     default:
  //       return opUnknownFailure(resp, await readTalerErrorResponse(resp));
  //   }
  // }

  /**
   * https://docs.taler.net/core/api-exchange.html#get--aml-$OFFICER_PUB-measures
   *
   */
  async getAmlMesasures(auth: OfficerAccount) {
    const url = new URL(`aml/${auth.id}/measures`, this.baseUrl);

    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
      headers: {
        "Taler-AML-Officer-Signature": buildAMLQuerySignature(auth.signingKey),
      },
    });

    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForAvailableMeasureSummary());
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  /**
   * https://docs.taler.net/core/api-exchange.html#get--aml-$OFFICER_PUB-measures
   *
   */
  async getAmlKycStatistics(auth: OfficerAccount, name: string, filter: {
    since?: Date
    until?: Date
  } = {}) {
    const url = new URL(`aml/${auth.id}/kyc-statistics/${name}`, this.baseUrl);

    if (filter.since !== undefined) {
      url.searchParams.set(
        "start_date",
        String(filter.since.getTime())
      );
    }
    if (filter.until !== undefined) {
      url.searchParams.set(
        "end_date",
        String(filter.until.getTime())
      );
    }


    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
      headers: {
        "Taler-AML-Officer-Signature": buildAMLQuerySignature(auth.signingKey),
      },
    });
    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForEventCounter());
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  /**
   * https://docs.taler.net/core/api-exchange.html#get--aml-$OFFICER_PUB-decisions
   *
   */
  async getAmlDecisions(auth: OfficerAccount, params: PaginationParams & {
    account?: string,
    active?: boolean,
    investigation?: boolean,
  } = {}) {
    const url = new URL(`aml/${auth.id}/decisions`, this.baseUrl);

    addMerchantPaginationParams(url, params);
    if (params.account !== undefined) {
      url.searchParams.set("h_payto", params.account);
    }
    if (params.active !== undefined) {
      url.searchParams.set("active", params.active ? "YES" : "NO");
    }
    if (params.investigation !== undefined) {
      url.searchParams.set("investigation", params.investigation ? "YES" : "NO");
    }

    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
      headers: {
        "Taler-AML-Officer-Signature": buildAMLQuerySignature(auth.signingKey),
      },
    });

    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForAmlDecisionsResponse());
      case HttpStatusCode.NoContent:
        return opFixedSuccess({ records: [] });
      case HttpStatusCode.Forbidden:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.Conflict:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  /**
   * https://docs.taler.net/core/api-exchange.html#get--aml-$OFFICER_PUB-attributes-$H_PAYTO
   *
   */
  async getAmlAttributesForAccount(auth: OfficerAccount, account: string, params: PaginationParams = {}) {
    const url = new URL(`aml/${auth.id}/attributes/${account}`, this.baseUrl);

    addMerchantPaginationParams(url, params);
    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
      headers: {
        "Taler-AML-Officer-Signature": buildAMLQuerySignature(auth.signingKey),
      },
    });

    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForAmlKycAttributes());
      case HttpStatusCode.NoContent:
        return opFixedSuccess({ details: [] });
      case HttpStatusCode.Forbidden:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.Conflict:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }


  /**
   * https://docs.taler.net/core/api-exchange.html#get--aml-$OFFICER_PUB-attributes-$H_PAYTO
   *
   */
  async makeAmlDesicion(auth: OfficerAccount, decision: Omit<AmlDecisionRequest, "officer_sig">) {
    const url = new URL(`aml/${auth.id}/decision`, this.baseUrl);

    const body = buildAMLDecisionSignature(auth.signingKey, decision);
    const resp = await this.httpLib.fetch(url.href, {
      method: "POST",
      headers: {
        "Taler-AML-Officer-Signature": buildAMLQuerySignature(auth.signingKey),
      },
      body,
    });

    switch (resp.status) {
      case HttpStatusCode.NoContent:
        return opEmptySuccess(resp);
      case HttpStatusCode.Forbidden:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.Conflict:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

}

function buildKYCQuerySignature(key: SigningKey): string {
  const sigBlob = buildSigPS(
    TalerSignaturePurpose.AML_QUERY,
  ).build();

  return encodeCrock(eddsaSign(sigBlob, key));
}

function buildAMLQuerySignature(key: SigningKey): string {
  const sigBlob = buildSigPS(
    TalerSignaturePurpose.AML_QUERY,
  ).build();

  return encodeCrock(eddsaSign(sigBlob, key));
}

function buildAMLDecisionSignature(
  key: SigningKey,
  decision: Omit<AmlDecisionRequest, "officer_sig">,
): AmlDecisionRequest {
  const zero = new Uint8Array(new ArrayBuffer(64));

  const sigBlob = buildSigPS(TalerSignaturePurpose.AML_DECISION)
    //TODO: new need the null terminator, also in the exchange
    .put(hash(stringToBytes(decision.justification))) //check null
    .put(timestampRoundedToBuffer(decision.decision_time))
    // .put(amountToBuffer(decision.new_threshold))
    .put(decodeCrock(decision.h_payto))
    .put(zero) //kyc_requirement
    // .put(bufferForUint32(decision.new_state))
    .build();

  const officer_sig = encodeCrock(eddsaSign(sigBlob, key));
  return {
    ...decision,
    officer_sig,
  };
}
