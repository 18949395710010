/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import {
  AccessToken,
  TalerHttpError,
  TalerMerchantManagementResultByMethod,
} from "@gnu-taler/taler-util";
import _useSWR, { SWRHook, mutate } from "swr";
import { useSessionContext } from "../context/session.js";
const useSWR = _useSWR as unknown as SWRHook;

export function revalidateInstanceDetails() {
  return mutate(
    (key) =>
      Array.isArray(key) && key[key.length - 1] === "getCurrentInstanceDetails",
    undefined,
    { revalidate: true },
  );
}
export function useInstanceDetails() {
  const { state, lib } = useSessionContext();

  async function fetcher([token]: [AccessToken]) {
    return await lib.instance.getCurrentInstanceDetails(token);
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"getCurrentInstanceDetails">,
    TalerHttpError
  >([state.token, "getCurrentInstanceDetails"], fetcher);

  if (data) return data;
  if (error) return error;
  return undefined;
}

export function revalidateInstanceKYCDetails() {
  return mutate(
    (key) =>
      Array.isArray(key) &&
      key[key.length - 1] === "getCurrentIntanceKycStatus",
    undefined,
    { revalidate: true },
  );
}
export function useInstanceKYCDetails() {
  const { state, lib } = useSessionContext();

  async function fetcher([token]: [AccessToken]) {
    return await lib.instance.getCurrentIntanceKycStatus(token, {});
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"getCurrentIntanceKycStatus">,
    TalerHttpError
  >([state.token, "getCurrentIntanceKycStatus"], fetcher);

  if (data) return data;
  if (error) return error;
  return undefined;
}

export function revalidateManagedInstanceDetails() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "getInstanceDetails",
    undefined,
    { revalidate: true },
  );
}
export function useManagedInstanceDetails(instanceId: string) {
  const { state, lib } = useSessionContext();

  async function fetcher([token, instanceId]: [AccessToken, string]) {
    return await lib.instance.getInstanceDetails(token, instanceId);
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"getInstanceDetails">,
    TalerHttpError
  >([state.token, instanceId, "getInstanceDetails"], fetcher);

  if (data) return data;
  if (error) return error;
  return undefined;
}

export function revalidateBackendInstances() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "listInstances",
    undefined,
    { revalidate: true },
  );
}
export function useBackendInstances() {
  const { state, lib } = useSessionContext();

  async function fetcher([token]: [AccessToken]) {
    return await lib.instance.listInstances(token);
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"listInstances">,
    TalerHttpError
  >([state.token, "listInstances"], fetcher);

  if (data) return data;
  if (error) return error;
  return undefined;
}
