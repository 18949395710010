/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { HttpStatusCode, TalerError, assertUnreachable } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { useInstanceKYCDetails } from "../../../../hooks/instance.js";
import { ListPage } from "./ListPage.js";

interface Props {
}

export default function ListKYC(_p: Props): VNode {
  const result = useInstanceKYCDetails();
  if (!result) return <Loading />
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />
  }
  /**
   * This component just render known kyc requirements.
   * If query fail then is safe to hide errors.
   */
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.GatewayTimeout: {
        return <div />
      }
      case HttpStatusCode.BadGateway: {
        return <ListPage status={result.body} />;

      }
      case HttpStatusCode.ServiceUnavailable: {
        return <div />
      }
      case HttpStatusCode.Unauthorized: {
        return <div />
      }
      case HttpStatusCode.NotFound: {
        return <div />;
      }
      default: {
        assertUnreachable(result)
      }
    }
  }
  const status = result.body;

  if (!status) {
    return <div>no kyc required</div>;
  }
  return <ListPage status={status} />;
}
