/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  AbsoluteTime,
  AmountJson,
  Amounts,
  TalerExchangeApi,
} from "@gnu-taler/taler-util";
import { format, parse } from "date-fns";
import { StringConverter } from "./FormProvider.js";

export const amlStateConverter = {
  toStringUI: stringifyAmlState,
  fromStringUI: parseAmlState,
};

function stringifyAmlState(s: TalerExchangeApi.AmlState | undefined): string {
  if (s === undefined) return "";
  switch (s) {
    case TalerExchangeApi.AmlState.normal:
      return "normal";
    case TalerExchangeApi.AmlState.pending:
      return "pending";
    case TalerExchangeApi.AmlState.frozen:
      return "frozen";
  }
}

function parseAmlState(s: string | undefined): TalerExchangeApi.AmlState {
  switch (s) {
    case "normal":
      return TalerExchangeApi.AmlState.normal;
    case "pending":
      return TalerExchangeApi.AmlState.pending;
    case "frozen":
      return TalerExchangeApi.AmlState.frozen;
    default:
      throw Error(`unknown AML state: ${s}`);
  }
}

const nullConverter: StringConverter<string> = {
  fromStringUI(v: string | undefined): string {
    return v ?? "";
  },
  toStringUI(v: unknown): string {
    return v as string;
  },
};

function amountConverter(config: any): StringConverter<AmountJson> {
  const currency = config["currency"];
  if (!currency || typeof currency !== "string") {
    throw Error(`amount converter needs a currency`);
  }
  return {
    fromStringUI(v: string | undefined): AmountJson {
      // FIXME: requires currency
      return (
        Amounts.parse(`${currency}:${v}`) ?? Amounts.zeroOfCurrency(currency)
      );
    },
    toStringUI(v: unknown): string {
      return v === undefined ? "" : Amounts.stringifyValue(v as AmountJson);
    },
  };
}

function absTimeConverter(config: any): StringConverter<AbsoluteTime> {
  const pattern = config["pattern"];
  if (!pattern || typeof pattern !== "string") {
    throw Error(`absTime converter needs a pattern`);
  }
  return {
    fromStringUI(v: string | undefined): AbsoluteTime {
      if (v === undefined) {
        return AbsoluteTime.never();
      }
      try {
        const time = parse(v, pattern, new Date());
        return AbsoluteTime.fromMilliseconds(time.getTime());
      } catch (e) {
        return AbsoluteTime.never();
      }
    },
    toStringUI(v: unknown): string {
      if (v === undefined) return "";
      const d = v as AbsoluteTime;
      if (d.t_ms === "never") return "never";
      try {
        return format(d.t_ms, pattern);
      } catch (e) {
        return "";
      }
    },
  };
}

export function getConverterById(
  id: string | undefined,
  config: unknown,
): StringConverter<unknown> {
  if (id === "Taler.AbsoluteTime") {
    // @ts-expect-error check this
    return absTimeConverter(config);
  }
  if (id === "Taler.Amount") {
    // @ts-expect-error check this
    return amountConverter(config);
  }
  if (id === "TalerExchangeApi.AmlState") {
    // @ts-expect-error check this
    return amlStateConverter;
  }
  return nullConverter as StringConverter<unknown>;
}
