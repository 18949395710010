/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  TalerMerchantApi,
  stringifyPayTemplateUri,
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { QR } from "../../../../components/exception/QR.js";
import { useSessionContext } from "../../../../context/session.js";

// type Entity = TalerMerchantApi.UsingTemplateDetails;

interface Props {
  contract: TalerMerchantApi.TemplateContractDetails;
  id: string;
  onBack?: () => void;
}

export function QrPage({ id: templateId, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();
  const { state } = useSessionContext();

  const merchantBaseUrl = state.backendUrl.href;

  const payTemplateUri = stringifyPayTemplateUri({
    merchantBaseUrl,
    templateId,
    // FIXME!
    //templateParams: {},
  });

  return (
    <div>
      <section id="printThis">
        <QR text={payTemplateUri} />
        <pre style={{ textAlign: "center" }}>
          <a target="_blank" rel="noreferrer" href={payTemplateUri}>
            {payTemplateUri}
          </a>
        </pre>
      </section>

      <section class="section is-main-section">
        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            <div class="buttons is-right mt-5">
              {onBack && (
                <button class="button" onClick={onBack}>
                  <i18n.Translate>Cancel</i18n.Translate>
                </button>
              )}
              <button
                class="button is-info"
                onClick={() => saveAsPDF(templateId)}
              >
                <i18n.Translate>Print</i18n.Translate>
              </button>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
    </div>
  );
}

function saveAsPDF(name: string): void {
  // TODO: Look into using media queries in the current page, to print the current page, instead of opening a new window

  const divContents = document.getElementById("printThis");
  if (!divContents) return;

  let dom = `<!DOCTYPE html>
<html>
  <head>
    <title>Order template for ${name}</title>
    <style>
      pre > a {
        text-decoration: none;
      }
    </style>
  </head>
  <body>
    ${divContents.outerHTML}
  </body>
</html>`;
  const blobUrl = URL.createObjectURL(new Blob([dom]));
  const printWindow = window.open(blobUrl, "", "height=400,width=800");
  if (!printWindow) return;
  printWindow.addEventListener("load", () => {
    printWindow.print();
    printWindow.close();
    URL.revokeObjectURL(blobUrl);
  });
}
