/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { PAGINATED_LIST_REQUEST } from "../utils/constants.js";

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import { AccessToken, OperationOk, TalerHttpError, TalerMerchantManagementResultByMethod } from "@gnu-taler/taler-util";
import _useSWR, { SWRHook, mutate } from "swr";
import { useSessionContext } from "../context/session.js";
const useSWR = _useSWR as unknown as SWRHook;

export interface InstanceWebhookFilter {
}

export function revalidateInstanceWebhooks() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "listWebhooks",
    undefined,
    { revalidate: true },
  );
}
export function useInstanceWebhooks() {
  const { state, lib } = useSessionContext();

  // const [offset, setOffset] = useState<string | undefined>();

  async function fetcher([token, _bid]: [AccessToken, string]) {
    return await lib.instance.listWebhooks(token, {
      // limit: PAGINATED_LIST_REQUEST,
      // offset: bid,
      // order: "dec",
    });
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"listWebhooks">,
    TalerHttpError
  >([state.token, "offset", "listWebhooks"], fetcher);

  if (error) return error;
  if (data === undefined) return undefined;
  if (data.type !== "ok") return data;

  // return buildPaginatedResult(data.body.webhooks, offset, setOffset, (d) => d.webhook_id)
  return data;
}

type PaginatedResult<T> = OperationOk<T> & {
  isLastPage: boolean;
  isFirstPage: boolean;
  loadNext(): void;
  loadFirst(): void;
}

//TODO: consider sending this to web-util
export function buildPaginatedResult<R, OffId>(data: R[], offset: OffId | undefined, setOffset: (o: OffId | undefined) => void, getId: (r: R) => OffId): PaginatedResult<R[]> {

  const isLastPage = data.length < PAGINATED_LIST_REQUEST;
  const isFirstPage = offset === undefined;

  const result = structuredClone(data);
  if (result.length == PAGINATED_LIST_REQUEST) {
    result.pop();
  }
  return {
    type: "ok",
    body: result,
    isLastPage,
    isFirstPage,
    loadNext: () => {
      if (!result.length) return;
      const id = getId(result[result.length - 1])
      setOffset(id);
    },
    loadFirst: () => {
      setOffset(undefined);
    },
  };
}


export function revalidateWebhookDetails() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "getWebhookDetails",
    undefined,
    { revalidate: true },
  );
}
export function useWebhookDetails(webhookId: string) {
  const { state, lib } = useSessionContext();

  async function fetcher([hookId, token]: [string, AccessToken]) {
    return await lib.instance.getWebhookDetails(token, hookId);
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"getWebhookDetails">,
    TalerHttpError
  >([webhookId, state.token, "getWebhookDetails"], fetcher);

  if (data) return data;
  if (error) return error;
  return undefined;
}
