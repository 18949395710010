/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { InputProps, useField } from "./useField.js";
import { DropdownList } from "./InputSearchOnList.js";

export interface Props<T> extends InputProps<T> {
  isValid?: (e: any) => boolean;
  getSuggestion?: (e: any) => Promise<{ id: string; description: string }[]>;
  addonBefore?: string;
  toStr?: (v?: any) => string;
  fromStr?: (s: string) => any;
  unique?: boolean;
}

const defaultToString = (f?: any): string => (f ? String(f) : "");
const defaultFromString = (v: string): any => v as any;

export function InputArray<T>({
  name,
  readonly,
  placeholder,
  tooltip,
  label,
  unique,
  help,
  addonBefore,
  getSuggestion,
  fromStr = defaultFromString,
  toStr = defaultToString,
}: Props<keyof T>): VNode {
  const { error, value, onChange, required } = useField<T>(name);

  const array: T[keyof T][] = value ? value! : [];
  const [currentValue, setCurrentValue] = useState("");
  const [suggestions, setSuggestions] = useState<
    { id: string; description: string }[]
  >([]);
  const { i18n } = useTranslationContext();

  return (
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          {label}
          {tooltip && (
            <span class="icon has-tooltip-right" data-tooltip={tooltip}>
              <i class="mdi mdi-information" />
            </span>
          )}
        </label>
      </div>
      <div class="field-body is-flex-grow-3">
        <div class="field">
          <div class="field has-addons">
            {addonBefore && (
              <div class="control">
                <a class="button is-static">{addonBefore}</a>
              </div>
            )}
            <p class="control is-expanded has-icons-right">
              <input
                class={error ? "input is-danger" : "input"}
                type="text"
                placeholder={placeholder}
                readonly={readonly}
                disabled={readonly}
                name={String(name)}
                value={currentValue}
                onChange={async (e): Promise<void> => {
                  const v = e.currentTarget.value;
                  setCurrentValue(v);
                  if (getSuggestion) {
                    getSuggestion(v).then((ss) => {
                      setSuggestions(ss);
                    });
                  }
                }}
              />
              {required && (
                <span class="icon has-text-danger is-right">
                  <i class="mdi mdi-alert" />
                </span>
              )}
            </p>
            {getSuggestion ? undefined : (
              <p class="control">
                <button
                  class="button is-info has-tooltip-left"
                  disabled={!currentValue}
                  onClick={(): void => {
                    const v = fromStr(currentValue);
                    if (!unique || array.indexOf(v) === -1) {
                      onChange([v, ...array] as T[keyof T]);
                    }
                    setCurrentValue("");
                  }}
                  data-tooltip={i18n.str`Add element to the list`}
                >
                  <i18n.Translate>Add</i18n.Translate>
                </button>
              </p>
            )}
          </div>
          {help}
          {error && <p class="help is-danger"> {error} </p>}
          
          {suggestions.length > 0 ? (
            <div>
              <DropdownList
                name={currentValue}
                list={suggestions}
                onSelect={(p): void => {
                  if (!unique || array.indexOf(p as any) === -1) {
                    onChange([p, ...array] as T[keyof T]);
                  }
                  setCurrentValue("");
                  setSuggestions([]);
                }}
                withImage={false}
              />
            </div>
          ) : undefined}
          {array.map((v, i) => (
            <div key={i} class="tags has-addons mt-3 mb-0">
              <span
                class="tag is-medium is-info mb-0"
                style={{ maxWidth: "90%" }}
              >
                {getSuggestion ? (v as any).description : toStr(v)}
              </span>
              <a
                class="tag is-medium is-danger is-delete mb-0"
                onClick={() => {
                  onChange(array.filter((f) => f !== v) as T[keyof T]);
                  setCurrentValue(getSuggestion ? (v as any).description : toStr(v));
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
