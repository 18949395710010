/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useSessionContext } from "../../context/session.js";
import { Entity } from "../../paths/admin/create/CreatePage.js";
import { Input } from "../form/Input.js";
import { InputDuration } from "../form/InputDuration.js";
import { InputGroup } from "../form/InputGroup.js";
import { InputImage } from "../form/InputImage.js";
import { InputLocation } from "../form/InputLocation.js";
import { InputToggle } from "../form/InputToggle.js";
import { InputWithAddon } from "../form/InputWithAddon.js";

export function DefaultInstanceFormFields({
  readonlyId,
  showId,
  showLessFields,
}: {
  readonlyId?: boolean;
  showLessFields?: boolean;
  showId: boolean;
}): VNode {
  const { i18n } = useTranslationContext();
  const { state } = useSessionContext();
  return (
    <Fragment>
      {showId && (
        <InputWithAddon<Entity>
          name="id"
          addonBefore={new URL("instances/", state.backendUrl.href).href}
          readonly={readonlyId}
          label={i18n.str`Identifier`}
          tooltip={i18n.str`Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.`}
        />
      )}

      <Input<Entity>
        name="name"
        label={i18n.str`Business name`}
        tooltip={i18n.str`Legal name of the business represented by this instance.`}
      />

      {showLessFields ? undefined : (
        <Fragment>
          <Input<Entity>
            name="email"
            label={i18n.str`Email`}
            tooltip={i18n.str`Contact email`}
          />

          <Input<Entity>
            name="website"
            label={i18n.str`Website URL`}
            tooltip={i18n.str`URL.`}
          />

          <InputImage<Entity>
            name="logo"
            label={i18n.str`Logo`}
            tooltip={i18n.str`Logo image.`}
          />

          <InputGroup
            name="address"
            label={i18n.str`Address`}
            tooltip={i18n.str`Physical location of the merchant.`}
          >
            <InputLocation name="address" />
          </InputGroup>

          <InputGroup
            name="jurisdiction"
            label={i18n.str`Jurisdiction`}
            tooltip={i18n.str`Jurisdiction for legal disputes with the merchant.`}
          >
            <InputLocation name="jurisdiction" />
          </InputGroup>

          <InputToggle<Entity>
            name="use_stefan"
            label={i18n.str`Pay transaction fee`}
            tooltip={i18n.str`Assume the cost of the transaction of let the user pay for it.`}
          />

          <InputDuration<Entity>
            name="default_pay_delay"
            label={i18n.str`Default payment delay`}
            withForever
            tooltip={i18n.str`Time customers have to pay an order before the offer expires by default.`}
          />

          <InputDuration<Entity>
            name="default_wire_transfer_delay"
            label={i18n.str`Default wire transfer delay`}
            tooltip={i18n.str`Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.`}
            withForever
          />
        </Fragment>
      )}
    </Fragment>
  );
}
