/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  HttpStatusCode,
  TalerError,
  TalerMerchantApi,
  assertUnreachable,
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../components/exception/loading.js";
import { NotificationCard } from "../../../components/menu/index.js";
import { DeleteModal, PurgeModal } from "../../../components/modal/index.js";
import { useSessionContext } from "../../../context/session.js";
import { useBackendInstances } from "../../../hooks/instance.js";
import { Notification } from "../../../utils/types.js";
import { LoginPage } from "../../login/index.js";
import { View } from "./View.js";

interface Props {
  onCreate: () => void;
  onUpdate: (id: string) => void;
  instances: TalerMerchantApi.Instance[];
}

export default function Instances({ onCreate, onUpdate }: Props): VNode {
  const result = useBackendInstances();
  const [deleting, setDeleting] = useState<TalerMerchantApi.Instance | null>(
    null,
  );
  const [purging, setPurging] = useState<TalerMerchantApi.Instance | null>(
    null,
  );
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { i18n } = useTranslationContext();
  const { state, lib } = useSessionContext();

  if (!result) return <Loading />;
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />;
  }
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />;
      }
      default: {
        assertUnreachable(result.case);
      }
    }
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />
      <View
        instances={result.body.instances}
        onDelete={setDeleting}
        onCreate={onCreate}
        onPurge={setPurging}
        onUpdate={onUpdate}
        selected={!!deleting}
      />
      {deleting && (
        <DeleteModal
          element={deleting}
          onCancel={() => setDeleting(null)}
          onConfirm={async (): Promise<void> => {
            if (state.status !== "loggedIn") {
              return;
            }
            try {
              const resp = await lib.instance.deleteInstance(
                state.token,
                deleting.id,
              );
              if (resp.type === "ok") {
                setNotif({
                  message: i18n.str`Instance "${deleting.name}" (ID: ${deleting.id}) has been deleted`,
                  type: "SUCCESS",
                });
              } else {
                setNotif({
                  message: i18n.str`Failed to delete instance`,
                  type: "ERROR",
                  description: resp.detail.hint,
                });
              }
            } catch (error) {
              setNotif({
                message: i18n.str`Failed to delete instance`,
                type: "ERROR",
                description: error instanceof Error ? error.message : undefined,
              });
              // pushNotification({message: 'delete_error', type: 'ERROR' })
            }
            setDeleting(null);
          }}
        />
      )}
      {purging && (
        <PurgeModal
          element={purging}
          onCancel={() => setPurging(null)}
          onConfirm={async (): Promise<void> => {
            if (state.status !== "loggedIn") {
              return;
            }
            try {
              const resp = await lib.instance.deleteInstance(
                state.token,
                purging.id,
                {
                  purge: true,
                },
              );
              if (resp.type === "ok") {
                setNotif({
                  message: i18n.str`Instance '${purging.name}' (ID: ${purging.id}) has been purge`,
                  type: "SUCCESS",
                });
              } else {
                setNotif({
                  message: i18n.str`Failed to purge instance`,
                  type: "ERROR",
                  description: resp.detail.hint,
                });
              }
            } catch (error) {
              setNotif({
                message: i18n.str`Failed to purge instance`,
                type: "ERROR",
                description: error instanceof Error ? error.message : undefined,
              });
            }
            setPurging(null);
          }}
        />
      )}
    </Fragment>
  );
}
