/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { TalerError, TalerMerchantApi } from "@gnu-taler/taler-util";
import {
  useTranslationContext
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { NotificationCard } from "../../../../components/menu/index.js";
import { useSessionContext } from "../../../../context/session.js";
import { useInstanceBankAccounts } from "../../../../hooks/bank.js";
import { Notification } from "../../../../utils/types.js";
import { CreatePage } from "./CreatePage.js";

export type Entity = TalerMerchantApi.TransferInformation;
interface Props {
  onBack?: () => void;
  onConfirm: () => void;
}

export default function CreateTransfer({ onConfirm, onBack }: Props): VNode {
  const { state, lib } = useSessionContext();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { i18n } = useTranslationContext();
  const instance = useInstanceBankAccounts();
  const accounts =
    !instance || instance instanceof TalerError || instance.type === "fail"
      ? []
      : instance.body.accounts.map((a) => a.payto_uri);

  return (
    <>
      <NotificationCard notification={notif} />
      <CreatePage
        onBack={onBack}
        accounts={accounts}
        onCreate={(request: TalerMerchantApi.TransferInformation) => {
          return lib.instance
            .informWireTransfer(state.token, request)
            .then((resp) => {
              if (resp.type === "ok") {
                setNotif({
                  message: i18n.str`Wire transfer informed successfully`,
                  type: "SUCCESS",
                });
                onConfirm()
              } else {
                setNotif({
                  message: i18n.str`Could not inform transfer`,
                  type: "ERROR",
                  description: resp.detail.hint,
                });
              }
            })
            .catch((error) => {
              setNotif({
                message: i18n.str`Could not inform transfer`,
                type: "ERROR",
                description: error instanceof Error ? error.message : String(error),
              });
            });
        }}
      />
    </>
  );
}
