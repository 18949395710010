import { ComponentChildren, h, VNode } from "preact";
import { useEffect, useState } from "preact/hooks";

export function CopyIcon(): VNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
    </svg>
  )
};

export function CopiedIcon(): VNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
    </svg>
  )
};

export function CopyButton({ class: clazz, children, getContent }: { children?: ComponentChildren, class: string, getContent: () => string }): VNode {
  const [copied, setCopied] = useState(false);
  function copyText(): void {
    if (!navigator.clipboard && !window.isSecureContext) {
      prompt("Clipboard is not available on insecure context (http).", getContent());
    }
    if (navigator.clipboard) {
      navigator.clipboard.writeText(getContent() || "");
      setCopied(true);
    }
  }
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  if (!copied) {
    return (
      <button class={clazz} onClick={e => {
        e.preventDefault()
        copyText()
      }} >
        <CopyIcon />
        {children}
      </button>
    );
  }
  return (
    <button class={clazz} disabled>
      <CopiedIcon />
      {children}
    </button>
  );
}
