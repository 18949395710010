/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { TalerMerchantApi } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";

export interface Props {
  status: TalerMerchantApi.AccountKycRedirects;
}

export function ListPage({ status }: Props): VNode {
  const { i18n } = useTranslationContext();

  return (
    <section class="section is-main-section">
      <div class="card has-table">
        <header class="card-header">
          <p class="card-header-title">
            <span class="icon">
              <i class="mdi mdi-clock" />
            </span>
            <i18n.Translate>Pending KYC verification</i18n.Translate>
          </p>

          <div class="card-header-icon" aria-label="more options" />
        </header>
        <div class="card-content">
          <div class="b-table has-pagination">
            <div class="table-wrapper has-mobile-cards">
              {status.pending_kycs.length > 0 ? (
                <PendingTable entries={status.pending_kycs} />
              ) : (
                <EmptyTable />
              )}
            </div>
          </div>
        </div>
      </div>

      {status.timeout_kycs.length > 0 ? (
        <div class="card has-table">
          <header class="card-header">
            <p class="card-header-title">
              <span class="icon">
                <i class="mdi mdi-clock" />
              </span>
              <i18n.Translate>Timed out</i18n.Translate>
            </p>

            <div class="card-header-icon" aria-label="more options" />
          </header>
          <div class="card-content">
            <div class="b-table has-pagination">
              <div class="table-wrapper has-mobile-cards">
                {status.timeout_kycs.length > 0 ? (
                  <TimedOutTable entries={status.timeout_kycs} />
                ) : (
                  <EmptyTable />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : undefined}
    </section>
  );
}
interface PendingTableProps {
  entries: TalerMerchantApi.MerchantAccountKycRedirect[];
}

interface TimedOutTableProps {
  entries: TalerMerchantApi.ExchangeKycTimeout[];
}

function PendingTable({ entries }: PendingTableProps): VNode {
  const { i18n } = useTranslationContext();
  return (
    <div class="table-container">
      <table class="table is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <i18n.Translate>Exchange</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Target account</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Reason</i18n.Translate>
            </th>
          </tr>
        </thead>
        <tbody>
          {entries.map((e, i) => {
            if (e.kyc_url === undefined) {
              // blocked by AML
              return (
                <tr key={i}>
                  <td>{e.exchange_url}</td>
                  <td>{e.payto_uri}</td>
                  <td>
                    {e.aml_status === 1 ? (
                      <i18n.Translate>
                        There is an anti-money laundering process pending to
                        complete.
                      </i18n.Translate>
                    ) : (
                      <i18n.Translate>
                        The account is frozen due to the anti-money laundering
                        rules. Contact the exchange service provider for further
                        instructions.
                      </i18n.Translate>
                    )}
                  </td>
                </tr>
              );
            } else {
              // blocked by KYC
              return (
                <tr key={i}>
                  <td>{e.exchange_url}</td>
                  <td>{e.payto_uri}</td>
                  <td>
                    <a href={e.kyc_url} target="_black" rel="noreferrer">
                      <i18n.Translate>
                        Pending KYC process, click here to complete
                      </i18n.Translate>
                    </a>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}

function TimedOutTable({ entries }: TimedOutTableProps): VNode {
  const { i18n } = useTranslationContext();
  return (
    <div class="table-container">
      <table class="table is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <i18n.Translate>Exchange</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Code</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Http Status</i18n.Translate>
            </th>
          </tr>
        </thead>
        <tbody>
          {entries.map((e, i) => {
            return (
              <tr key={i}>
                <td>{e.exchange_url}</td>
                <td>{e.exchange_code}</td>
                <td>{e.exchange_http_status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function EmptyTable(): VNode {
  const { i18n } = useTranslationContext();
  return (
    <div class="content has-text-grey has-text-centered">
      <p>
        <span class="icon is-large">
          <i class="mdi mdi-emoticon-happy mdi-48px" />
        </span>
      </p>
      <p>
        <i18n.Translate>No pending kyc verification!</i18n.Translate>
      </p>
    </div>
  );
}
