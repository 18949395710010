/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { AbsoluteTime, TalerMerchantApi } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { format } from "date-fns";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { DatePicker } from "../../../../components/picker/DatePicker.js";
import { dateFormatForSettings, usePreference } from "../../../../hooks/preference.js";
import { CardTable } from "./Table.js";
import { WithId } from "../../../../declaration.js";

export interface ListPageProps {
  onShowAll: () => void;
  onShowNotPaid: () => void;
  onShowPaid: () => void;
  onShowRefunded: () => void;
  onShowNotWired: () => void;
  onShowWired: () => void;
  onCopyURL: (id: string) => void;
  isAllActive: string;
  isPaidActive: string;
  isNotPaidActive: string;
  isRefundedActive: string;
  isNotWiredActive: string;
  isWiredActive: string;

  jumpToDate?: AbsoluteTime;
  onSelectDate: (date?: AbsoluteTime) => void;

  orders: (TalerMerchantApi.OrderHistoryEntry & WithId)[];
  onLoadMoreBefore?: () => void;
  onLoadMoreAfter?: () => void;

  onSelectOrder: (o: TalerMerchantApi.OrderHistoryEntry & WithId) => void;
  onRefundOrder: (o: TalerMerchantApi.OrderHistoryEntry & WithId) => void;
  onCreate: () => void;
}

export function ListPage({
  onLoadMoreAfter,
  onLoadMoreBefore,
  orders,
  isAllActive,
  onSelectOrder,
  onRefundOrder,
  jumpToDate,
  onCopyURL,
  onShowAll,
  onShowPaid,
  onShowNotPaid,
  onShowRefunded,
  onShowNotWired,
  onShowWired,
  onSelectDate,
  isPaidActive,
  isRefundedActive,
  isNotWiredActive,
  onCreate,
  isNotPaidActive,
  isWiredActive,
}: ListPageProps): VNode {
  const { i18n } = useTranslationContext();
  const dateTooltip = i18n.str`Select date to show nearby orders`;
  const [pickDate, setPickDate] = useState(false);
  const [settings] = usePreference();

  return (
    <Fragment>
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="tabs" style={{ overflow: "inherit" }}>
            <ul>
              <li class={isNotPaidActive}>
                <div
                  class="has-tooltip-right"
                  data-tooltip={i18n.str`Only show paid orders`}
                >
                  <a onClick={onShowNotPaid}>
                    <i18n.Translate>New</i18n.Translate>
                  </a>
                </div>
              </li>
              <li class={isPaidActive}>
                <div
                  class="has-tooltip-right"
                  data-tooltip={i18n.str`Only show paid orders`}
                >
                  <a onClick={onShowPaid}>
                    <i18n.Translate>Paid</i18n.Translate>
                  </a>
                </div>
              </li>
              <li class={isRefundedActive}>
                <div
                  class="has-tooltip-right"
                  data-tooltip={i18n.str`Only show orders with refunds`}
                >
                  <a onClick={onShowRefunded}>
                    <i18n.Translate>Refunded</i18n.Translate>
                  </a>
                </div>
              </li>
              <li class={isNotWiredActive}>
                <div
                  class="has-tooltip-left"
                  data-tooltip={i18n.str`Only show orders where customers paid, but wire payments from payment provider are still pending`}
                >
                  <a onClick={onShowNotWired}>
                    <i18n.Translate>Not wired</i18n.Translate>
                  </a>
                </div>
              </li>
              <li class={isWiredActive}>
                <div
                  class="has-tooltip-left"
                  data-tooltip={i18n.str`Only show orders where customers paid, but wire payments from payment provider are still pending`}
                >
                  <a onClick={onShowWired}>
                    <i18n.Translate>Completed</i18n.Translate>
                  </a>
                </div>
              </li>
              <li class={isAllActive}>
                <div
                  class="has-tooltip-right"
                  data-tooltip={i18n.str`Remove all filters`}
                >
                  <a onClick={onShowAll}>
                    <i18n.Translate>All</i18n.Translate>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="column ">
          <div class="buttons is-right">
            <div class="field has-addons">
              {jumpToDate && (
                <div class="control">
                  <a class="button is-fullwidth" onClick={() => onSelectDate(undefined)}>
                    <span
                      class="icon"
                      data-tooltip={i18n.str`Clear date filter`}
                    >
                      <i class="mdi mdi-close" />
                    </span>
                  </a>
                </div>
              )}
              <div class="control">
                <span class="has-tooltip-top" data-tooltip={dateTooltip}>
                  <input
                    class="input"
                    type="text"
                    readonly
                    value={!jumpToDate || jumpToDate.t_ms === "never" ? "" : format(jumpToDate.t_ms, dateFormatForSettings(settings))}
                    placeholder={i18n.str`Jump to date (${dateFormatForSettings(settings)})`}
                    onClick={() => {
                      setPickDate(true);
                    }}
                  />
                </span>
              </div>
              <div class="control">
                <span class="has-tooltip-left" data-tooltip={dateTooltip}>
                  <a
                    class="button  is-fullwidth"
                    onClick={() => {
                      setPickDate(true);
                    }}
                  >
                    <span class="icon">
                      <i class="mdi mdi-calendar" />
                    </span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DatePicker
        opened={pickDate}
        closeFunction={() => setPickDate(false)}
        dateReceiver={(d) => {
          onSelectDate(AbsoluteTime.fromMilliseconds(d.getTime()))
        }}
      />

      <CardTable
        orders={orders}
        onCreate={onCreate}
        onCopyURL={onCopyURL}
        onSelect={onSelectOrder}
        onRefund={onRefundOrder}
        onLoadMoreAfter={onLoadMoreAfter}
        onLoadMoreBefore={onLoadMoreBefore}
      />
    </Fragment>
  );
}
