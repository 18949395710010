/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { useState } from "preact/hooks";
import { PAGINATED_LIST_REQUEST } from "../utils/constants.js";

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import { AccessToken, TalerHttpError, TalerMerchantManagementResultByMethod } from "@gnu-taler/taler-util";
import _useSWR, { SWRHook, mutate } from "swr";
import { useSessionContext } from "../context/session.js";
const useSWR = _useSWR as unknown as SWRHook;


export interface InstanceTemplateFilter {
}

export function revalidateInstanceTemplates() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "listTemplates",
    undefined,
    { revalidate: true },
  );
}
export function useInstanceTemplates() {
  const { state, lib } = useSessionContext();

  const [offset] = useState<string | undefined>();

  async function fetcher([token, bid]: [AccessToken, string]) {
    return await lib.instance.listTemplates(token, {
      limit: PAGINATED_LIST_REQUEST,
      offset: bid,
      order: "dec",
    });
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"listTemplates">,
    TalerHttpError
  >([state.token, offset, "listTemplates"], fetcher);

  if (error) return error;
  if (data === undefined) return undefined;
  if (data.type !== "ok") return data;

  // return buildPaginatedResult(data.body.templates, offset, setOffset, (d) => d.template_id)
  return data;

}

export function revalidateTemplateDetails() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "getTemplateDetails",
    undefined,
    { revalidate: true },
  );
}
export function useTemplateDetails(templateId: string) {
  const { state, lib } = useSessionContext();

  async function fetcher([tid, token]: [string, AccessToken]) {
    return await lib.instance.getTemplateDetails(token, tid);
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"getTemplateDetails">,
    TalerHttpError
  >([templateId, state.token, "getTemplateDetails"], fetcher);

  if (data) return data;
  if (error) return error;
  return undefined;
}
